import { useState, useContext, useEffect } from 'react'
import RatingSelect from './RatingSelect'
import Card from './shared/Card'
import Button from './shared/Button'
import FeedbackContext from '../context/FeedbackContext'
// handleAdd is prop of function passed from App.js
function FeedbackForm() {
    const [text, setText] = useState('')
    const [rating, setRating] = useState(10)
    const [btnDisabled, setbtnDisabled] = useState(true)
    const [message, setMessage] = useState('')

    const { addFeedback, feedbackEdit, updateFeedback } = useContext(FeedbackContext)

    useEffect(
        () => {
            if (feedbackEdit.edit) {
                setbtnDisabled(false)
                setText(feedbackEdit.item.text)
                setRating(feedbackEdit.item.rating)
            }
        },
        [feedbackEdit]
    )
    const handleSubmit = (e) => {
        e.preventDefault()
        if (text.trim().length > 10) {
            const newFeedback = {
                // text: text,
                // rating: rating,
                // Since text and rating names are the same (text:text,rating:rating) we can shorthand to text,rating
                text,
                rating,
            }
            // Now executing App.js -> handleAdd() with new feedback
            if (feedbackEdit.edit === true) {
                updateFeedback(feedbackEdit.item.id, newFeedback)
            } else {

                addFeedback(newFeedback)
            }
            setText('')
        }
    }

    const handleTextChange = (e) => {
        // Not working correctly... text is set too late
        if (text === '') {
            setbtnDisabled(true)
            setMessage(null)
        } else if (text !== '' && text.trim().length <= 10) {
            setMessage('Text must be at least 10 characters')
            setbtnDisabled(true)
        } else {
            setMessage(null)
            setbtnDisabled(false)
        }
        setText(e.target.value)
    }
    return (
        <Card>
            <form onSubmit={handleSubmit}>
                <h2>How would you rate your service?</h2>
                {/* Passing inline function select to RatingSelectjsx */}
                <RatingSelect select={(rating) => setRating(rating)} />
                <div className="input-group">
                    <input value={text} onChange={handleTextChange} type="text" placeholder="Write a review" />
                    <Button type="submit" isDisabled={btnDisabled}>Send</Button>
                </div>
                {message &&
                    <div className="message">{message}</div>
                }
            </form>
        </Card>
    )
}

export default FeedbackForm
