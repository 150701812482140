import PropTypes from 'prop-types' //impt

function Header({ text, bgcolor, textcolor }) {
    const headerStyles = {
        backgroundColor: bgcolor, color: textcolor
    }

    return (
        <div>
            <header style={headerStyles}>
                <div className="container">
                    <h2>{text}</h2>
                </div>
            </header>
        </div>
    )
}

Header.defaultProps = {
    text: 'Default Text',
    bgcolor: 'black',
    textcolor: 'white',
}

Header.propTypes = {
    text: PropTypes.string
}
export default Header
