// run "npm i uuid" to install
// run "npm i framer-motion@4.1.17" to install specific version of framer motion
// run "npm i react-router-dom" to install the router dom
// import {useParams} from 'react-router-dom' to allow url parameters to be directly accessed 
// e.g. Route path='/post/:id/:filter' - access 1st/2nd params as {params.id} {params.filter}


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Header from "./components/Header"
import FeedbackList from './components/FeedbackList'
import FeedbackStats from './components/FeedbackStats'
import FeedbackForm from './components/FeedbackForm'
import AboutPage from './pages/AboutPage'
import { FeedbackProvider } from './context/FeedbackContext'
import AboutIconLink from './components/AboutIconLink'
import Post from './components/Post'


function App() {



    return (
        // FeedbackProvider - this is the provider containing the global state (or context)
        <FeedbackProvider>

            <Router>
                <Header text='Text passed in' textcolor='red' />
                <div className="container">
                    <Routes>
                        <Route exact path='/' element={
                            <>
                                <FeedbackForm />
                                <FeedbackStats />
                                <FeedbackList />
                            </>
                        }>

                        </Route>

                        <Route path='/about' element={<AboutPage />} />
                        {/* /> means self closing tag instead of ending with </Route> */}
                        <Route path='/post/*' element={<Post />} />
                    </Routes>

                    <AboutIconLink />
                </div>
            </Router>

        </FeedbackProvider>

    )
}

export default App
// Notes: Learn map/filter/foreach