import PropTypes from 'prop-types'
function Card({ reverse, children }) {
    return (
        <div className={`card ${reverse && 'reverse'}`}>
            {children}
        </div>
    )

    // or could have 
    // return (
    //     <div className='card' style={{
    //         backgroundColor: reverse ? 'rgba(0,0,0,0.4)' : '#fff',
    //         color: reverse ? '#fff' : '#000',

    //     }}>
    //         {children}
    //     </div>
    // )
}

Card.defaultProps = {
    reverse: false,
}

Card.propTypes = {
    reverse: PropTypes.bool,
    children: PropTypes.node.isRequired,
}
export default Card
