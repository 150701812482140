import { Navigate, useNavigate, Routes, Route } from 'react-router-dom'

function Post() {
    const status = 200
    const navigate = useNavigate()

    const onClick = () => {
        console.log('clicked')
        navigate('/about')
    }

    if (status === 404) {
        return <Navigate to='/notfound' />
    }



    return (
        <div>
            <h1>POST</h1>
            <button onClick={onClick}>Test Reroute</button>
            <Routes>
                <Route path='/show' element={<h1>A SHOW</h1>} />

            </Routes>
        </div>
    )
}
export default Post
